import { findIndex, validateField } from "./utils";
import {
  PropertyBoxType,
  valueFields,
  valueNames,
} from "../Block/blockMenuList";
export const validateAllValueFields = (
  field1: any,
  field2: any,
  field3: any,
  value: any,
  propertyBoxState: any,
  errorList: any,
  setErrorList: any,
) => {
  validateField(field1, value, propertyBoxState, errorList, setErrorList);
  if (!field1.error) {
    validateField(
      field2,
      field2.value,
      propertyBoxState,
      errorList,
      setErrorList,
    );
    validateField(
      field3,
      field3.value,
      propertyBoxState,
      errorList,
      setErrorList,
    );
  }
};

const selectTypes: any = {
  select: "select",
  multi: "multiSelect",
  radio: "radio",
};
export const validateValueFields = (
  field: any,
  value: any,
  propertyBoxState: any,
  errorList: any,
  setErrorList: any,
) => {
  try {
    const valueFieldIndex = findIndex(Object.values(propertyBoxState), "value");
    const minFieldIndex = findIndex(
      Object.values(propertyBoxState),
      "minLength",
    );
    const maxFieldIndex = findIndex(
      Object.values(propertyBoxState),
      "maxLength",
    );
    const minField =
      minFieldIndex >= 0
        ? Object.values(propertyBoxState)[minFieldIndex]
        : propertyBoxState[findIndex(Object.values(propertyBoxState), "min")];
    const maxField =
      minFieldIndex >= 0
        ? Object.values(propertyBoxState)[maxFieldIndex]
        : propertyBoxState[findIndex(Object.values(propertyBoxState), "max")];
    const valueField = Object.values(propertyBoxState)[
      valueFieldIndex
    ] as PropertyBoxType;
    if (field.key === "value")
      validateAllValueFields(
        valueField,
        minField,
        maxField,
        value,
        propertyBoxState,
        errorList,
        setErrorList,
      );
    else if (["minLength", "min"].includes(field.key))
      validateAllValueFields(
        minField,
        maxField,
        valueField,
        value,
        propertyBoxState,
        errorList,
        setErrorList,
      );
    else
      validateAllValueFields(
        maxField,
        minField,
        valueField,
        value,
        propertyBoxState,
        errorList,
        setErrorList,
      );
  } catch (e) {
    console.error(e);
  }
};

export const onInputComponentChange = (
  field: any,
  value: any,
  propertyBoxState: any,
  errorList: any,
  setErrorList: any,
) => {
  if (
    valueFields.includes(field.key) &&
    valueNames.includes(field.name) &&
    !selectTypes[field.type]
  )
    validateValueFields(
      field,
      value,
      propertyBoxState,
      errorList,
      setErrorList,
    );
  else validateField(field, value, propertyBoxState, errorList, setErrorList);
};
