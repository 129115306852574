import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { get } from "lodash";

export const defaultBlock = {
  type: { label: "One", value: "w-full", no_column: 1, category: "LAYOUT" },
  blocks: [null],
};

export const defaultLayout = [
  {
    type: { label: "One", value: "w-full", no_column: 1, category: "TITLE" },
    blocks: [
      {
        properties: {
          label: "Submit",
          name: "button",
        },
        type: "button",
      },
      {
        properties: {
          size: { label: "Heading 1", value: "text-3xl" },
          label: "Add your label",
          value: "Form Title",
        },
        type: "text",
      },
    ],
  },
  { ...defaultBlock },
];

export const rootUrl =
  process.env.REACT_APP_BASE_URL || "https://top-api.talview.org";

export const updateFormBuilderApi = createApi({
  reducerPath: "form_id",
  baseQuery: graphqlRequestBaseQuery({
    url: `${rootUrl}/v1/graphql`,
    prepareHeaders: (headers) => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("accessToken");
      const paramsLength = urlParams.toString().split("&").length;
      if (token && paramsLength === 1) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("x-hasura-role", `TENANT_ADMIN`);
      }
      return headers;
    },
  }),
  tagTypes: ["Draft", "Published"],

  endpoints: (builder: any) => ({
    getFormDraft: builder.query({
      query: ({ id }: any) => ({
        document: gql`
            query MyQuery {
              frm_form_by_pk(id: ${id}) {
                form_versions(where: {status: {_eq: Draft}}) {
                  structure
                }
                id
                name
                type
              }
            }
          `,
      }),
      providesTags: ["Draft"],
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { name, type, form_versions },
        } = response || {};
        return { name, type, structure: get(form_versions, "0.structure") };
      },
    }),
    getFormStructure: builder.query({
      query: ({ id }: { id: number }) => ({
        document: gql`
                query {
                  frm_form_by_pk(id: ${id}) {
                    id
                    form_versions {
                      id
                      structure
                      status
                      version
                    }
                    type
                  }
                }
          `,
      }),
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { type, form_versions },
        } = response || {};
        return { type, structure: get(form_versions, "0.structure") };
      },
      providesTags: ["Published"],
    }),
    createForm: builder.mutation({
      query: ({ name, type }: { name: string; type: string }) => ({
        document: gql`
          mutation ($name: String!, $type: String!) {
            frm_create_form(name: $name, type: $type) {
              data {
                id
                name
                type
              }
            }
          }
        `,
        variables: {
          name,
          type,
        },
      }),
    }),
    updateForm: builder.mutation({
      query: ({ form_id, structure }: { form_id: string; structure: any }) => {
        return {
          document: gql`
            mutation ($form_id: Int!, $structure: jsonb!) {
              frm_update_form_version(
                form_id: $form_id
                structure: $structure
              ) {
                form_id
                structure
              }
            }
          `,
          variables: {
            form_id,
            structure: structure,
          },
        };
      },
    }),
    publishForm: builder.mutation({
      query: ({ form_id }: { form_id: number }) => ({
        document: gql`
          mutation ($form_id: Int!) {
            frm_publish_form(form_id: $form_id) {
              form_id
              status
              structure
            }
          }
        `,
        variables: {
          form_id,
        },
      }),
      invalidatesTags: ["Published"],
    }),
  }),
});

export const {
  useGetFormDraftQuery,
  useGetFormStructureQuery,
  useCreateFormMutation,
  useUpdateFormMutation,
  usePublishFormMutation,
} = updateFormBuilderApi;
