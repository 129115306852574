import _ from "lodash";

export const isDate = (d: string) => {
  if (!d) return true;
  const date = d.trim();
  const isDateFormat = !(
    date.split("-").filter((el: string) => el === "").length > 0
  );

  if (
    date.split("-").length !== 3 ||
    !isDateFormat ||
    isNaN(Date.parse(date))
  ) {
    return false;
  }

  return true;
};

export const isDuplicate = (arr: any[]) => {
  const labelArr = arr.map((item: any) => item.label);
  const valueArr = arr.map((item: any) => item.value);
  const isDuplicateValue = valueArr.some((item: any, idx: number) => {
    return valueArr.indexOf(item) !== idx;
  });
  const isDuplicateLabel = labelArr.some((item: any, idx: number) => {
    return labelArr.indexOf(item) !== idx;
  });

  return !(isDuplicateLabel || isDuplicateValue);
};

export const isArrayEqual = (x: any, y: any) => {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
};
