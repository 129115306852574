import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { forEach, get } from "lodash";
import classNames from "classnames";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateBlockNote } from "@blocknote/react";
import "@blocknote/core/fonts/inter.css";
import { useGetFormStructureQuery } from "store/services/updateFormBuilder";
import { getElement } from "utils/getElement";
import { customBlocksTypes } from "components/Tools/CustomBlocks";
import {
  handleSubmit,
  initForm,
  initobj,
  postFormData,
  postFormFile,
} from "containers/FormRenderer";
import { Data, FormStateType } from "Pages/Editor";
import { applyConditionalLogic } from "containers/BlockNoteRenderer/utils";
import Loader from "components/Loader";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";
import { schema } from "components/BlockNoteEditor";
import { options } from "components/Tools/LogicBlock";
import { FORM_TYPE } from "utils/constant";

interface FormRendererProps {
  setFormState?: (state: FormStateType) => void;
  formType?: string;
  formContent?: any[];
}

interface ContentItem {
  type: keyof typeof customBlocksTypes;
  props: {
    attrkey?: string;
    value?: any;
    layout?: { value: number };
    label?: string;
    hide?: boolean;
  } & LogicProps;
}

type HtmlBlock = ReactElement | string;
export type LogicProps = {
  targetBlocks: options[];
  actionBlock: options;
  operation: options;
  actionBlockOptions: options[];
};

export const getContent = (contentItem: HtmlBlock) => {
  if (typeof contentItem === "string") {
    return (
      <div
        className="bn-default-styles"
        dangerouslySetInnerHTML={{ __html: contentItem }}
      />
    );
  }
  if (React.isValidElement(contentItem)) {
    return (
      <div className="bn-default-styles w-full max-w-sm pb-4">
        {contentItem}
      </div>
    );
  }
  return <></>;
};

export const renderContent = (
  htmlContent: HtmlBlock[],
  blocksLayout: number[],
  deviceType: "DESKTOP" | "MOBILE",
) => {
  let currentGridItems: HtmlBlock[] = [];
  let currentLayoutType = blocksLayout?.[0];

  const grids: HtmlBlock[] = [];

  forEach(htmlContent, (contentItem, index) => {
    const layoutType = blocksLayout[index];
    // If layoutType changes or the current grid is full
    if (
      layoutType !== currentLayoutType ||
      currentGridItems.length >= layoutType
    ) {
      // Add the current grid to the list of grids
      grids.push(
        <div
          key={`grid-${index}`}
          className={classNames(
            "grid gap-4 grid-cols-auto-fit-minmax-200",
            deviceType !== "MOBILE" &&
              (currentLayoutType === 1
                ? "sm:grid-cols-1"
                : currentLayoutType === 2
                  ? "sm:grid-cols-2"
                  : "sm:grid-cols-3"),
          )}
        >
          {currentGridItems}
        </div>,
      );

      // Reset current grid items and update layout type
      currentGridItems = [];
      currentLayoutType = layoutType;
    }

    // Add the contentItem to the current grid
    currentGridItems.push(getContent(contentItem));

    // Handle the last grid which may not be added in the loop
    if (index === htmlContent.length - 1) {
      grids.push(
        <div
          key={`grid-${index}-end`}
          className={`grid grid-cols-${currentLayoutType} gap-4`}
        >
          {currentGridItems}
        </div>,
      );
    }
  });

  return grids;
};

const BlockNoteRenderer: React.FC<FormRendererProps> = ({
  setFormState,
  formType,
  formContent,
}) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const editor = useCreateBlockNote({ schema });

  const [deviceType, setDeviceType] = useState<"DESKTOP" | "MOBILE">("DESKTOP");
  const [htmlContent, setHtmlContent] = useState<HtmlBlock[]>([]);
  const logicsRef = useRef<LogicProps[]>([]);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [blocksLayout, setBlocksLayout] = useState<number[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const { data, status } = useGetFormStructureQuery(
    {
      id,
      state: "renderer",
    },
    { skip: !!formType },
  );
  const { type, structure } = (data || {}) as Data;

  const formTypeValue = type || formType;

  const content = (Array.isArray(structure) &&
    structure.length > 0 &&
    structure) ||
    formContent || [{}];

  useEffect(() => {
    initForm(searchParams, initobj);
    const eventMethod = "addEventListener";
    const eventer = window[eventMethod];
    const messageEvent = "message";

    eventer(messageEvent, (event) => {
      //Trigger formSubmit from parent (For Recruit)
      if (event.data.type === "submitEvaluationForm") {
        document.getElementById("submitButton")?.click();
        // postPersistFormData(null, true);
      }
    });
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      const htmlBlocks: HtmlBlock[] = [];
      const layout: number[] = [];
      const conditionalLogics = [];
      for (const item of content) {
        const { type, props } = (item as ContentItem) || {};
        let blockElement = null;
        if (type === customBlocksTypes.logic) {
          conditionalLogics.push(props);
          continue;
        }
        if (customBlocksTypes[type]) {
          const { attrkey, label, hide } = props || {};
          const element = React.cloneElement(getElement(type), {
            ...props,
            onChange: (e: any) => {
              if (type === customBlocksTypes.file) {
                const file = get(e, "files.0");
                postFormFile(file, attrkey);
              } else setData(attrkey, e);
            },
          });
          layout.push(props?.layout?.value || 1);
          blockElement = (
            <div id={attrkey || label} className={classNames(hide && "hidden")}>
              {element}
            </div>
          );
        } else {
          blockElement = await editor?.blocksToFullHTML([item]);
          layout.push(1);
        }
        htmlBlocks.push(blockElement);
      }
      setHtmlContent(htmlBlocks);
      setBlocksLayout(layout);
      logicsRef.current = conditionalLogics;
    })();
  }, [content]);

  const setData = (name: any, e: any) => {
    if (name) {
      setFormData((data) => {
        return {
          ...data,
          [name]: e,
        };
      });
      applyConditionalLogic(logicsRef.current, name, e?.value);
    }
  };

  const grids = useMemo(
    () => renderContent(htmlContent, blocksLayout, deviceType),
    [htmlContent, blocksLayout, deviceType],
  );

  return (
    <div className="max-h-screen w-full min-h-screen bg-white block-render">
      {formType && (
        <div className="cursor-pointer w-full flex justify-end p-5 bg-white">
          <div className="mr-3">
            <Toggle
              labelClassNames="text-gray-500"
              label={"Mobile View"}
              onChange={(checked: boolean) => {
                if (checked) setDeviceType("MOBILE");
                else setDeviceType("DESKTOP");
              }}
              size="sm"
              checked={deviceType === "MOBILE"}
            />
          </div>
          <div
            role="button"
            data-testid="back-to-editor"
            onClick={() => setFormState && setFormState("builder")}
            className="text-sm font-light	text-gray-500	"
            title="Back to Editor"
          >
            <FontAwesomeIcon icon={solid("right-from-line")} />
            Back to Editor
          </div>
        </div>
      )}
      <div
        className={classNames(
          "mx-auto mt-2 px-5 py-10 bg-white overflow-auto",
          formType
            ? " h-[85vh] max-h-[85vh] " +
                (deviceType === "MOBILE" ? "max-w-sm shadow-lg" : "max-w-6xl")
            : "max-h-screen h-screen",
        )}
      >
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          id={
            formTypeValue === FORM_TYPE.REGISTRATION
              ? "assessmentForm"
              : "evaluationForm"
          }
        >
          {status === "pending" ? (
            <div className="h-full flex items-center justify-center grow">
              <Loader />
            </div>
          ) : (
            <div className="bn-container">{grids}</div>
          )}
          <input type="submit" className="hidden" />
        </form>
        <div className="w-100 text-left pl-2.5">
          <button
            id="submitButton"
            className={`border rounded py-2 px-3 mt-10 bg-blue-700 ${
              formTypeValue !== FORM_TYPE.REGISTRATION && "hidden"
            } text-white text-sm font-medium text-center`}
            onClick={() => postFormData(formData, formRef)}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlockNoteRenderer;
