import React, { memo, useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Select, { components } from "react-select";
import { get } from "lodash";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";

export const reactSelectDefaultStyles = {
  input: (props: any) => ({
    ...props,
    input: {
      boxShadow: "none",
    },
  }),
  indicatorSeparator: (props: any) => ({
    ...props,
    display: "none",
  }),
  placeholder: (props: any) => ({
    ...props,
    color: "#333940",
    opacity: 0.4,
    fontWeight: 400,
    fontSize: "14px",
  }),
  option: (props: any, option: any) => ({
    ...props,
    fontSize: "14px",
    backgroundColor: option.isSelected ? "#1d4ed8" : props.backgroundColor,
  }),
  singleValue: (props: any) => ({
    ...props,
    fontSize: "14px",
  }),
  multiValue: (props: any) => ({
    ...props,
    backgroundColor: "#DBEAFE",
    borderRadius: "4px",
    padding: "0px 8px 0px 8px",
    marginRight: "8px",
  }),
  multiValueLabel: (props: any) => ({
    ...props,
    color: "#1D4ED8",
    fontSize: "12px",
    fontWeight: "500",
  }),
  multiValueRemove: (props: any) => ({
    ...props,
    "&:hover": {
      backgroundColor: "transparent",
      color: "#1D4ED8",
    },
    color: "#1D4ED8",
  }),
  control: (provided: any, state: any) => {
    const borderColor =
      state && state.selectProps && state.selectProps.error
        ? "#b91c1c"
        : "#E5E7EB";
    return {
      ...provided,
      borderColor,
      boxShadow: "none !important",
      "&:hover": {
        borderColor: "none",
        cursor: "pointer",
      },
    };
  },
};

export function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="w-6 h-6" />
    </components.DropdownIndicator>
  );
}

const SelectDropdown = (props: any) => {
  const {
    value,
    onChange,
    fieldEnableChange,
    disabled,
    readOnly,
    isLoading,
    isClearable,
    isSearchable,
    name,
    placeholder,
    options,
    primaryKey,
    labelKey,
    emptyLabel,
    customStyles,
    isMulti,
    error,
    label,
    required,
    hasToggle,
    isEnabled,
  } = props;
  const [selectedValue, setSelectedValue] = useState(value);
  const [isToggled, setIsToggled] = useState(isEnabled || false);
  const noOptionsMessage = () => emptyLabel;
  const inputRef = useRef<any>(null);
  const formatOptionLabel = (option: any) => get(option, labelKey, option);
  const getOptionValue = (option: any) => get(option, primaryKey, option);
  const controlStyles = (provided: any, state: any) =>
    reactSelectDefaultStyles.control(provided, {
      ...state,
      selectProps: { ...state.selectProps, error },
    });

  const updateValue = (value: any) => {
    onChange(value);
    setSelectedValue(value);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setIsToggled(isEnabled);
  }, [isEnabled]);

  useEffect(() => {
    if (required && !selectedValue?.value)
      inputRef.current?.setCustomValidity(`This is a required field`);
    else inputRef.current?.setCustomValidity("");
  }, [selectedValue]);

  return (
    <div>
      <input
        ref={inputRef}
        name={name}
        value={selectedValue}
        defaultValue={value || ""}
        id={name}
        className="absolute opacity-0 pointer-events-none"
        role="button"
      />
      <div className="text-sm relative flex-col" data-testid="select_button">
        {label && (
          <div className="flex justify-between items-center">
            <label
              htmlFor={name}
              className={`block text-sm h-[1rem] mb-[0.5rem] font-normal text-[#201F1E] not-italic}`}
              data-testid="input-label"
            >
              {label}
              {required && "*"}
            </label>
            {hasToggle && (
              <Toggle
                checked={isEnabled}
                onChange={(e: boolean) => {
                  setIsToggled(e);
                  fieldEnableChange && fieldEnableChange(e);
                }}
              />
            )}
          </div>
        )}
        {(!hasToggle || (hasToggle && isToggled)) && (
          <>
            <Select
              data-testid="select_button"
              value={selectedValue}
              onChange={updateValue}
              isDisabled={disabled || readOnly}
              isLoading={isLoading}
              isClearable={isClearable}
              isSearchable={isSearchable}
              name={name}
              placeholder={placeholder}
              options={options}
              getOptionValue={getOptionValue}
              getOptionLabel={formatOptionLabel}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={noOptionsMessage}
              styles={{
                ...reactSelectDefaultStyles,
                ...customStyles,
                control: controlStyles,
              }}
              components={{
                DropdownIndicator,
              }}
              isMulti={isMulti}
              menuPlacement={"auto"}
            />
            {error && <p className="text-red-700 mt-1 text-xs">{error}</p>}
          </>
        )}
      </div>
    </div>
  );
};

SelectDropdown.defaultProps = {
  name: "dropdown",
  isSearchable: false,
  isMulti: false,
};

export default memo(SelectDropdown);
