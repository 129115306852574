import {isArrayEqual} from "../../utils";

export const setLayoutStateEffect = async (layouts: any, layoutState: any, setLayoutState: any) => {
  if (layouts && !isArrayEqual(layoutState, layouts)) setLayoutState(layouts);
}

export const publishFormState = async (id: string|undefined, publishForm: any, navigate: any) => {
  try {
    await publishForm({ form_id: id });
    navigate(`/renderer/${id}`);
  } catch (err) {
    console.error(err);
  }
};
