import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import Loader from "../Loader";
import React from "react";

type SavedIndicatorProps = {
  isUpdateLoading: boolean;
}

const SavedIndicator = ({isUpdateLoading} : SavedIndicatorProps) => {
  return (
    <div className={"mr-[0.81rem] px-[0.62rem] py-[0.75rem] w-[6.25rem]"}>
      {!isUpdateLoading? <div className="flex items-center justify-center font-medium  text-sm text-[#6B7280]">
          <FontAwesomeIcon icon={faCheck} className="mr-[0.5rem]" />
          Saved
        </div> :
        <div className="flex items-center justify-center justify-items-center">
          <Loader />
          <div className="text-sm text-[#6B7280] font-medium">Saving..</div>
        </div>}
    </div>
  )
}
export default SavedIndicator;
