import React, { useState, useEffect } from "react";
import { useBlockNoteEditor, useEditorChange } from "@blocknote/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faTimeline } from "@fortawesome/pro-light-svg-icons";
import SelectDropdown from "components/Tools/Select";
import {
  logicOperations,
  logicOperators,
  multiSelectSupportedOperators,
} from "components/Tools/CustomBlocks/utils";

export type options = {
  label: string;
  value: string;
  hide?: boolean;
};

interface LogicBlockProps {
  getBlocks: () => any;
  getSupportedBlocks: () => any;
  getBlockValues: () => any;
  onActionBlockSelect: (selected: options) => void;
  onActionBlockOptionsSelect: (selected: options) => void;
  onTargetBlockSelect: (selected: options) => void;
  onOperationSelect: (selected: options) => void;
  onOperatorSelect: (selected: options) => void;
  blockProps: any;
}

const validTargetBlocks = (
  blocks: options[],
  actionBlock: options,
  operation: options,
) => {
  const isShow = operation?.value === "show";
  return blocks?.filter(
    (block) =>
      block?.value !== actionBlock?.value &&
      (isShow ? block?.hide : !block?.hide),
  );
};

const LogicBlock: React.FC<LogicBlockProps> = ({
  getBlocks,
  getSupportedBlocks,
  getBlockValues,
  onActionBlockSelect,
  onActionBlockOptionsSelect,
  onTargetBlockSelect,
  onOperationSelect,
  onOperatorSelect,
  blockProps,
}) => {
  const editor = useBlockNoteEditor();
  const { operator, operation, actionBlock, actionBlockOptions, targetBlocks } =
    blockProps || {};
  const [blocksSupported, setBlocksSupported] = useState(getSupportedBlocks());
  const [blockValues, setBlockValues] = useState(getBlockValues());
  const [blocks, setBlocks] = useState(getBlocks());
  const [filteredBlocks, setFilteredBlocks] = useState(
    validTargetBlocks(blocks, actionBlock, operation),
  );
  const [selectedBlock, setSelectedBlock] = useState<options | null>(
    actionBlock,
  );

  const onBlockSelect = (selected: options) => {
    if (actionBlock?.value !== selected?.value) {
      setFilteredBlocks(validTargetBlocks(blocks, selected, operation));
      setSelectedBlock(selected);
      onActionBlockSelect(selected);
    }
  };

  const operationSelect = (selected: options) => {
    if (operation?.value !== selected?.value) {
      setFilteredBlocks(validTargetBlocks(blocks, actionBlock, selected));
      onOperationSelect(selected);
    }
  };

  const callback = () => {
    setBlocks(getBlocks());
    setBlocksSupported(getSupportedBlocks());
    setBlockValues(getBlockValues());
  };

  useEffect(() => {
    setFilteredBlocks(validTargetBlocks(blocks, actionBlock, operation));
  }, [blocks]);

  useEditorChange(callback, editor);

  return (
    <div className="flex flex-col gap-3 border-l-4 p-2 text-sm font-normal">
      <div className="flex items-center gap-3">
        <div className="flex justify-center items-center w-6">
          <FontAwesomeIcon icon={faTimeline} />
        </div>
        <div className="text-gray-700 w-10 text-center">When</div>
        <div className="min-w-40">
          <SelectDropdown
            options={blocksSupported}
            labelKey="label"
            primaryKey="value"
            onChange={onBlockSelect}
            value={selectedBlock}
          />
        </div>
        <div className="min-w-20">
          <SelectDropdown
            options={logicOperators}
            labelKey="label"
            primaryKey="value"
            onChange={onOperatorSelect}
            value={operator}
          />
        </div>
        <div className="min-w-40 max-w-full">
          <SelectDropdown
            options={selectedBlock?.value && blockValues[selectedBlock?.value]}
            labelKey="label"
            primaryKey="value"
            onChange={onActionBlockOptionsSelect}
            value={actionBlockOptions}
            isMulti={multiSelectSupportedOperators.includes(operator?.value)}
          />
        </div>
      </div>
      <div>
        <div className="flex items-center gap-3 w-full">
          <div className="flex justify-center items-center w-6">
            <FontAwesomeIcon icon={faBolt} />
          </div>
          <div className="text-gray-700 w-10 text-center">Then</div>
          <div className="min-w-40">
            <SelectDropdown
              options={logicOperations}
              labelKey="label"
              primaryKey="value"
              onChange={operationSelect}
              value={operation}
              placeholder="select action"
            />
          </div>
          <div className="min-w-60">
            <SelectDropdown
              options={filteredBlocks}
              labelKey="label"
              primaryKey="value"
              onChange={onTargetBlockSelect}
              placeholder="Select option"
              value={targetBlocks}
              isMulti
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogicBlock;
