import { propertyBox, validationBox } from "../Block/blockMenuList";
import { Field } from "./index";
import { TextOptions } from "../Tools/Text";
import { get } from "lodash";

export function resetFieldsEffect(
  block: any,
  setFields: (value: ((prevState: Field[]) => Field[]) | Field[]) => void,
  setPropertyBoxState: (obj: any) => any,
  menuFieldsRef: any,
) {
  if (block) {
    const oldProps = propertyBox[block?.type] ?? [];
    let newProps = oldProps.map((curProp: any) => {
      let newProp = { ...curProp };
      if (newProp?.hasToggle) {
        newProp.isEnabled =
          get(block, `properties.toggleState.${newProp.key}`) || false;
      }
      if (newProp.error) newProp.errormsg = newProp.error;
      newProp.validations = Object.keys(validationBox[block.type])
        .map((key: any) => {
          return key === curProp.key ? validationBox[block.type][key] : null;
        })
        .filter((curr: any) => curr !== null);
      if (block.properties && block.properties.hasOwnProperty(curProp.key)) {
        if (newProp.key === "size") newProp.options = TextOptions;
        newProp.value = block.properties[curProp.key];
        if (newProp.key === "options") {
          newProp.options = block.properties[curProp.key];
        }
      }
      const firstValidation = get(newProp, "validations.0"); // _.noop is a placeholder function if validations[0] is not present

      newProp.error = !newProp.value
        ? false //If value is blank no error or
        : firstValidation
          ? firstValidation(newProp.value)
            ? false // if block level validation exists check that passes no error or
            : newProp.errormsg
          : newProp.error; // Fail : error, no validations : return unchanged
      return newProp;
    });
    setFields(newProps);
    setPropertyBoxState({
      validations: validationBox[block.type] ?? [],
      ...newProps,
    });
    if (menuFieldsRef) menuFieldsRef.current = newProps;
  }
}

export const updateLayoutEffect = (
  fields: Field[],
  updateLayoutState: (
    value: ((prevState: Field[]) => Field[]) | Field[],
  ) => void,
) => {
  // stub of a function that will correctly update the layout state
  if (fields.length) {
    updateLayoutState(fields);
  }
};
