import { PropertyBoxType } from "../Block/blockMenuList";
import Toggle from "../Popover/MenuPopover/ToggleSwitch";
import InputList, { InputProps } from "../Popover/MenuPopover/InputList";
import Select from "../Tools/Select";
import React from "react";
import { updateProperties } from "./utils";
import Input from "../Tools/Input";
import DatePicker from "../Tools/DatePicker";

const onMultiSelectChanged = (
  propertyBoxState: any,
  setPropertyBoxState: any,
  option: any,
) => {
  const optionsIndex = getIndex(propertyBoxState, { key: "fileTypes" });
  const updatedPropertyBoxState = {
    ...propertyBoxState,
    [optionsIndex]: { ...propertyBoxState[optionsIndex], value: option },
  };

  setPropertyBoxState(updatedPropertyBoxState);
};

const getIndex = (propertyBox: any, box: { key: string }) =>
  Object.keys(propertyBox).findIndex(
    (key) => propertyBox[key]?.key === box?.key,
  );

const getSelectValue = (properties: any, box: any) => {
  if (box.type === "multiSelect") {
    return properties[getIndex(properties, box)]?.value || [];
  }
  if (properties && properties[getIndex(properties, box)]) {
    return properties[getIndex(properties, box)]?.value?.value;
  } else if (box.value) {
    return box.value[0];
  } else {
    return properties.options.find(
      (option: any) => properties?.value?.value === option.value,
    );
  }
};

const getOptions = (propertyBoxState: any, box: any) => {
  const x =
    propertyBoxState[getIndex(propertyBoxState, { key: "options" })]?.value;
  return x ?? box.options;
};

export const getInputComponent = (
  box: PropertyBoxType,
  propertyBoxState: any,
  setPropertyBoxState: (obj: any) => any,
  errorList: any,
  setErrorList: (obj: any) => any,
) => {
  const uniqueId = `propBox_${box.key}`;
  switch (box.type) {
    case "checkbox":
      return (
        <Toggle
          label={box.name}
          onChange={(checked: boolean) => {
            updateProperties(
              checked,
              box.key,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            );
          }}
          checked={box?.value}
          data-testid={uniqueId}
        />
      );
    case "input":
      return (
        <Input
          label={box.name}
          name={box.name}
          error={propertyBoxState[getIndex(propertyBoxState, box)]?.error}
          value={
            propertyBoxState && propertyBoxState[box.key]
              ? propertyBoxState[box.key]
              : ""
          }
          onChange={(value: any) =>
            updateProperties(
              value,
              box.key,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            )
          }
          data-testid={uniqueId}
        />
      );
    case "inputList":
      return (
        <InputList
          onChange={(optionList: InputProps[] | InputProps) => {
            updateProperties(
              optionList,
              box.key,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            );
          }}
          label={box.name}
          options={getOptions(propertyBoxState, box) ?? []}
          error={propertyBoxState[getIndex(propertyBoxState, box)]?.error}
          isSingle={!!box?.isSingle}
          data-testid={uniqueId}
        />
      );
    case "select":
      return (
        <Select
          onChange={(option: any) =>
            updateProperties(
              option,
              box.key,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            )
          }
          name={box.name}
          options={getOptions(propertyBoxState, box)}
          label={box.name}
          value={getSelectValue(propertyBoxState, box)}
          data-testid={uniqueId}
          labelKey="label"
          primaryKey="value"
          isClearable
        />
      );
    case "multiSelect":
      return (
        <Select
          isMulti
          isSearchable
          onChange={(option: any) => {
            onMultiSelectChanged(propertyBoxState, setPropertyBoxState, option);
          }}
          name={box.name}
          options={getOptions(propertyBoxState, box)}
          label={box.name}
          value={getSelectValue(propertyBoxState, box)}
          labelKey="label"
          primaryKey="value"
          data-testid={uniqueId}
        />
      );
    case "datepicker":
      return (
        <DatePicker
          onChange={(value: any) =>
            updateProperties(
              value,
              box.key,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            )
          }
          label={box.name}
          value={
            propertyBoxState && propertyBoxState[box.key]
              ? propertyBoxState[box.key]
              : ""
          }
          min={
            propertyBoxState?.min && box.key !== "min" && box.key !== "max"
              ? propertyBoxState.min
              : ""
          }
          max={
            propertyBoxState?.max && box.key !== "min" && box.key !== "max"
              ? propertyBoxState.max
              : ""
          }
          data-testid={uniqueId}
        />
      );
    default:
      return <></>;
  }
};
