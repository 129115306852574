import CheckboxTree from "components/Tools/Checkbox/CheckboxTree";
import DatePicker from "components/Tools/DatePicker";
import Input from "components/Tools/Input";
import RadioButtonGroup from "components/Tools/RadioButtonGroup";
import Select from "components/Tools/Select";
import Text from "components/Tools/Text";
import TextArea from "components/Tools/TextArea";
import File from "components/Tools/File";
import Button from "components/Button";

export const getElement = (type: string) => {
  switch (type) {
    case "input":
      return <Input type="text" labelSize="sm" />;
    case "number":
      return <Input type="number" labelSize="sm" />;
    case "email":
      return <Input type="email" labelSize="sm" />;
    case "checkbox":
      return <CheckboxTree />;
    case "radio":
      return <RadioButtonGroup />;
    case "text":
      return <Text />;
    case "datepicker":
      return <DatePicker />;
    case "select":
      return (
        <Select
          labelKey="label"
          primaryKey="value"
          isDisabled
          isClearable
          isSearchable
        />
      );
    case "textarea":
      return <TextArea labelSize="sm" />;
    case "file":
      return <File />;
    case "button":
      return <Button />;
    default:
      return <></>;
  }
};
