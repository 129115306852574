import { insertOrUpdateBlock } from "@blocknote/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { layoutOptions } from "components/Block/blockMenuList";

export const blocksSchema = {
  input: {
    required: { default: false },
    hide: { default: false },
    placeholder: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    minLength: { default: null },
    maxLength: { default: null },
    pattern: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
        minLength: false,
        maxLength: false,
        pattern: false,
        placeholder: false,
      },
    },
  },
  number: {
    required: { default: false },
    hide: { default: false },
    placeholder: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    min: { default: null },
    max: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
        min: false,
        max: false,
        placeholder: false,
      },
    },
  },
  email: {
    required: { default: false },
    hide: { default: false },
    placeholder: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
        placeholder: false,
      },
    },
  },
  checkbox: {
    required: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    label: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    layout: { default: layoutOptions[2] },
  },
  textarea: {
    required: { default: false },
    hide: { default: false },
    placeholder: { default: null },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    minLength: { default: null },
    maxLength: { default: null },
    pattern: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
        minLength: false,
        maxLength: false,
        pattern: false,
      },
    },
  },
  select: {
    required: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
      },
    },
  },
  radio: {
    required: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    options: {
      default: [
        {
          label: "Option1",
          name: "Option1",
          id: "Option1",
          value: "Option1",
        },
      ],
    },
    rowAlign: { default: false },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
      },
    },
  },
  datepicker: {
    required: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    label: { default: null },
    value: { default: null },
    min: { default: null },
    max: { default: null },
    placeholder: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        value: false,
        min: false,
        max: false,
        placeholder: false,
      },
    },
  },
  file: {
    required: { default: false },
    hide: { default: false },
    attrkey: { default: null },
    label: { default: null },
    fileTypes: { default: [] },
    size: { default: null },
    layout: { default: layoutOptions[2] },
    toggleState: {
      default: {
        fileTypes: false,
        size: false,
      },
    },
  },
  logic: {
    actionBlock: { default: null },
    actionBlockOptions: { default: null },
    targetBlocks: { default: null },
    operator: { default: { label: "Is", value: "is" } },
    operation: { default: { label: "Hide block", value: "hide" } },
  },
};

export const logicOperations = [
  { label: "Show block", value: "show" },
  { label: "Hide block", value: "hide" },
];

export const logicOperators = [
  { label: "Is", value: "is" },
  { label: "Is any of", value: "isAnyof" },
];

export const multiSelectSupportedOperators = ["isAnyof"];

export const insertBlock = (
  editor: any,
  type: string,
  title: string,
  icon?: any,
  group?: string,
) => ({
  title,
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type,
    });
  },
  icon: <FontAwesomeIcon icon={icon} size="lg" />,
  group: group || "Input Blocks",
});
