import React, { useRef, memo } from "react";
import BlockNoteEditor, {
  BlockEditor,
  EditorInstance,
} from "components/BlockNoteEditor";

export interface FormUpdate {
  form_id: string | undefined;
  structure: any[];
}

interface FormBuilderProps {
  id: string | undefined;
  updateForm: (form: FormUpdate) => void;
  formContent: any[];
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  id,
  updateForm,
  formContent,
}) => {
  const editorRef = useRef<EditorInstance | null>(null);
  return (
    <div className="mt-2 px-5 py-10 bg-white overflow-auto">
      <BlockNoteEditor
        ref={editorRef}
        formContent={formContent}
        isEditable
        onChange={() => {
          const editor = editorRef?.current?.getBlockEditor() as BlockEditor;
          if (editor.document) {
            updateForm({
              form_id: id,
              structure: editor.document,
            });
          }
        }}
      />
    </div>
  );
};

export default memo(FormBuilder);
