import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import { PropertyBoxType } from "components/Block/blockMenuList";
import { resetFieldsEffect } from "components/SidePanel/effects";
import { getInputComponent } from "components/SidePanel/inputComponents";
import { getFieldProps } from "components/SidePanel/utils";
import { onInputComponentChange } from "components/SidePanel/blockValidators";
import { Field } from "components/SidePanel";

export interface MenuBoxProps {
  propertyBox: Field[];
  setProperties?: (obj: object) => void;
  validations: object;
  block?: object;
  properties?: object;
  menuFieldsRef?: any;
}

const MenuBox: React.FC<MenuBoxProps> = ({
  propertyBox,
  validations,
  block,
  menuFieldsRef,
}) => {
  const [errorList, setErrorList] = useState<any>({});
  const [fields, setFields] = useState<Field[]>(
    menuFieldsRef?.current || propertyBox,
  );
  const [propertyBoxState, setPropertyBoxState] = useState<any>({
    ...fields,
    validations,
  });

  useLayoutEffect(() => {
    if (!menuFieldsRef?.current)
      resetFieldsEffect(block, setFields, setPropertyBoxState, menuFieldsRef);
  }, []);

  useEffect(() => {
    if (menuFieldsRef) menuFieldsRef.current = fields;
  }, [fields]);

  return (
    <div className="flex flex-col gap-2 p-2 bg-white" data-testid="menubox">
      {fields?.map((field: PropertyBoxType, index: number) => (
        <div key={"box.type" + index} data-testid="menubox-element">
          {React.cloneElement(
            getInputComponent(
              field,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            ),
            {
              ...getFieldProps(field, propertyBoxState),
              onChange: (value: string) =>
                onInputComponentChange(
                  field,
                  value,
                  propertyBoxState,
                  errorList,
                  setErrorList,
                ),
              fieldEnableChange: (value: boolean) => {
                const newPropertyBoxState = { ...propertyBoxState };
                Object.values(newPropertyBoxState).map((box: any) => {
                  let newBox = box;
                  if (box && box?.key === field?.key) {
                    newBox.isEnabled = value;
                  }
                  return newBox;
                });
              },
            },
          )}
        </div>
      ))}
    </div>
  );
};
export default memo(MenuBox);
