import { get } from "lodash";
import { LogicProps } from "./index";

export const applyConditionalLogic = (
  logics: LogicProps[],
  attrkey: string,
  value: string,
) => {
  try {
    logics?.forEach((logic) => {
      const actionBlock = get(logic, "actionBlock.label");
      const targetBlocks = get(logic, "targetBlocks");
      const operation = get(logic, "operation.value");
      const options = get(logic, "actionBlockOptions", []);
      const option = options?.some((option) => option?.value === value);
      if (actionBlock === attrkey && targetBlocks.length > 0 && operation) {
        targetBlocks?.forEach((targetBlock) => {
          const targetElement = document.getElementById(targetBlock?.label);
          if (targetElement) {
            const shouldHide = option
              ? operation === "hide"
              : operation === "show";
            targetElement.style.display = shouldHide ? "none" : "block";
          }
        });
      }
    });
  } catch (e) {
    console.error(e);
  }
};
