/**
 *
 * Input
 *
 */

import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AddImage } from "images/AddImage";
import { find } from "lodash";
import { DeleteIcon } from "images/DeleteIcon";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import { BytesPerMB, FileOptions } from "components/Block/blockMenuList";
export const File = (props: any) => {
  const {
    required,
    label,
    value,
    name,
    disabled,
    fileTypes,
    size,
    error,
    onChange,
    readOnly,
    ...rest
  } = props;
  const inputRef = useRef<any>(null);
  const [inputValue, setInputValue] = useState<string>(value);
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);
  const fileSelected = inputRef.current?.files[0];
  if (required && !fileSelected)
    inputRef.current?.setCustomValidity(`This is a required field`);
  else if (
    inputRef?.current?.value &&
    fileSelected?.size > parseInt(props.size) * BytesPerMB
  ) {
    inputRef.current?.setCustomValidity(
      `Exceeds file size of ${props.size} MB`,
    );
  } else {
    inputRef.current?.setCustomValidity("");
  }
  const fileTypeList = (fileTypes?.length > 0 ? fileTypes : FileOptions)
    .map((type: any) => type.name)
    .join(", ");
  return (
    <div
      className={classNames(
        "gap-8 cursor-pointer bg-white",
        disabled && "opacity-50",
      )}
      onClick={() => !disabled && !readOnly && inputRef.current.click()}
    >
      <div className="flex justify-between">
        {label ? (
          <label
            htmlFor={name}
            className={`block text-sm h-4 mb-2 font-normal text-stone-900 not-italic
              ${disabled && "group group-hover:cursor-pointer"}
            `}
            data-testid="input-label"
          >
            {label}
            {required === true && "*"}
          </label>
        ) : (
          <div />
        )}
      </div>
      <div className="flex gap-3 rounded-md shadow-sm w-full border-2 border-dashed p-2">
        <AddImage />
        <div className="flex flex-col justify-center gap-1">
          <div className="group-cursor-pointer justify-start items-center gap-1 inline-flex cursor-pointer">
            {inputValue ? (
              <div className="flex gap-1 text-gray-600 text-sm font-medium leading-tight">
                Uploaded file
                <div
                  data-testid="cancel_button"
                  onClick={(e) => {
                    setInputValue("");
                    e.stopPropagation();
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            ) : (
              <div className="text-sm font-medium leading-tight">
                <span className="text-blue-600 mr-2">Upload a file</span>
                <span className="text-gray-600 ">or drag and drop</span>
              </div>
            )}
          </div>
          <div className="text-left text-gray-500 text-xs font-normal leading-none">
            {!inputValue ? (
              <div
                className={"w-60 text-gray-500 text-xs font-normal"}
              >{`${fileTypeList} ${size ? `up to ${size}MB` : ""}`}</div>
            ) : (
              `${
                find(FileOptions, (t) => t.ext.includes(fileSelected?.type))
                  ?.name
              } ${(fileSelected?.size / BytesPerMB).toPrecision(1)} MB`
            )}
          </div>
        </div>
        <input
          {...rest}
          ref={inputRef}
          type="file"
          accept={fileTypes?.map((type: any) => type.ext.join(",")).join(",")}
          capture="user"
          name={name}
          onChange={(e) => {
            setInputValue(e.target.value);
            onChange(e.target);
          }}
          id={name}
          className="opacity-0 pointer-events-none hidden"
          aria-describedby={`${name}-error`}
          data-testid={rest["data-testid"] || "input"}
          role="button"
        />
      </div>
      {error && (
        <p
          className="font-normal text-xs text-rose-800 mt-2"
          id={`${name}-description`}
          data-testid="input_error_msg"
        >
          {error}
        </p>
      )}
    </div>
  );
};

File.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(File);
