import {applyChangesToFields, errorOrNone, findIndex} from "./utils";
import {errorType, valueFields, fromDateString} from "components/Block/blockMenuList";
export const validateAndApply = (fields : any, propertyBoxState : any, updateLayoutState : (obj : any)=> any) => {
  let newFields = [...fields];
  applyChangesToFields(newFields, propertyBoxState);
  if( !errorOrNone(newFields))
    updateLayoutState && updateLayoutState(newFields);
};


export const ValType = {
  DATE: "date",
  NUM: "number",
  TEXT: "text"
}
export const Operators : any = {
  "date": (a: any, b: any) =>  fromDateString(a) >= fromDateString(b),
  "number": (a: any, b: any) => parseInt(a) >= parseInt(b),
  "text": (a: any, b: any) => parseInt(a) >= parseInt(b),
};

export const BlockErrors : any = {
  "date":
    {
      "minMax": errorType.minGreaterThanMax,
      "valMax": errorType.defaultGreaterThanMax,
      "valMin": errorType.defaultLessThanMin,
    },
  "number":
    {
      "minMax": errorType.minGreaterThanMax,
      "valMax": errorType.defaultGreaterThanMax,
      "valMin": errorType.defaultLessThanMin,
    },
  "text":
    {
      "minMax": errorType.minGreaterThanMax,
      "valMax": errorType.defaultLongerThanMax,
      "valMin": errorType.defaultShorterThanMin,
    },
}
export const validateValues = (min : any, val : any, max : any, i : string ) => {
  if(min && max && !Operators[i](max,min)) // if min and max value exist and min > max
    return BlockErrors[i].minMax;
  if(val && max && !Operators[i](max,val)) // if val and max value exists and max < val
    return BlockErrors[i].valMax;
  if(val && min && !Operators[i](val,min)) { // if val and min value exists and min > val
    return BlockErrors[i].valMin;
  }
  return false;
}

export const checkForNumberErrors = (field : any, newFields : any) => {
  const minValueIndex = findIndex(newFields, "minLength");
  const maxValueIndex = findIndex(newFields, "maxLength");
  const valueIndex = findIndex(newFields, "value");
  const maxValue = newFields[maxValueIndex]?.value;
  const minValue = newFields[minValueIndex]?.value;
  const defaultValue = newFields[valueIndex]?.value;
  if(!field)
    return field;
  // Error is at field level, so skip the rest until field validators pass
  if(field.error && !Object.values(BlockErrors).map((Error:any) => Object.values(Error).includes(field.error)).includes(true))
    return field;
  //If the name field has no value,
  else if(field.key === 'attrkey')
    field.error = !field.value? errorType.emptyName : false;
  //If the field is blank
  else if(!field.value)
    field.error = false;
  //If the field is a value field
  else if(valueFields.includes( field.key )) {
    //If a field is of type datepicker
    const minIndex = findIndex(newFields, "min");
    if (minIndex > -1) {
      const maxIndex = findIndex(newFields, "max");
      const minValue = newFields[minIndex]?.value;
      const maxValue = newFields[maxIndex]?.value;
      if (newFields[minIndex]?.errormsg === errorType.invalidDate)
        field.error = validateValues(minValue, defaultValue, maxValue, ValType.DATE);
      else if (newFields[minIndex]?.errormsg === errorType.invalidMinValue)  // Type Number
        field.error = validateValues(minValue, defaultValue, maxValue, ValType.NUM);
    }
    else if (newFields[minValueIndex]?.errormsg === errorType.invalidMinLength) { // Type Length
      field.error = validateValues(minValue, defaultValue?.length, maxValue, ValType.TEXT);
    }
  }

  return field;
}
