import { memo, useEffect, useState } from "react";
import Input from "../../Tools/Input";
import { DeleteIcon } from "images/DeleteIcon";
import { PlusIcon } from "images/PlusIcon";
import { diff } from "components/SidePanel/utils";

export type InputProps = {
  label?: string;
  value?: string;
  name?: string;
  no_column?: number;
  id?: string;
};

type InputListProps = {
  onChange: (value: InputProps[] | InputProps) => void;
  label: string;
  options: InputProps[];
  isSingle: boolean;
  error?: string | null;
};
const InputList = ({
  onChange,
  options,
  label,
  isSingle,
  error = "",
}: InputListProps) => {
  const [list, setList] = useState<InputProps[]>([
    {
      label: "",
      value: "",
      name: "",
    },
  ]);

  useEffect(() => {
    if (options?.length > 0) {
      setList(options);
    }
  }, [options]);

  const updateListValue = (obj: InputProps, index: number) => {
    const inputList = list.map((list) => ({
      label: list?.label,
      name: list?.name,
      id: list?.id,
      value: list?.value,
    }));

    if (typeof obj.value === "string") {
      inputList[index].value = obj.value.trim();
      inputList[index].id = obj.value.trim();
    }
    if (typeof obj.label === "string") {
      inputList[index].label = obj.label.trim();
      inputList[index].name = obj.label.replace(" ", "");
    }
    setList([...inputList]);

    if (inputList[index].label && inputList[index].value) {
      if (isSingle) onChange(inputList[0]);
      else onChange(inputList);
    }
  };

  const addInputToList = () => {
    setList([...list, { label: "", value: "" }]);
  };

  const deleteInput = (index: number) => {
    const inputList = [...list];
    inputList.splice(index, 1);

    onChange && onChange(inputList);
    setList(inputList);
  };

  return (
    <div className={"flex-col space-y-2 w-full"}>
      <label htmlFor="label" className="h-5 text-lg font-semibold">
        {label}
      </label>
      <div className="flex items-center mt-8 mb-4">
        <label htmlFor="label" className="ml-2 mr-2.5 flex text-sm w-44 h-4">
          Label
        </label>
        <label htmlFor="value" className=" flex text-sm">
          Value
        </label>
      </div>
      {list.map((input: InputProps, index: number) => (
        <div
          className="flex items-center mb-3 gap-1"
          key={label + index}
          data-testid="inputlist_items"
        >
          <div className="">
            <Input
              data-testid="inputlist_label"
              aria-label="label"
              value={input.label}
              onChange={(label: string) => {
                if (diff(input.label ?? "", input.value ?? "").length === 1) {
                  updateListValue({ label, value: label }, index);
                } else updateListValue({ label }, index);
              }}
            />
          </div>
          <div className="">
            <Input
              data-testid="inputlist_input"
              aria-label="value"
              value={input.value}
              onChange={(value: string) => updateListValue({ value }, index)}
            />
          </div>
          {!isSingle && (
            <div
              data-testid="inputlist_delete"
              className={`cursor-pointer flex items-center ${
                list.length <= 1 ? "opacity-25 cursor-not-allowed" : ""
              }`}
              onClick={() => list.length > 1 && deleteInput(index)}
            >
              <DeleteIcon />
            </div>
          )}
        </div>
      ))}
      {error && <div className="text-xs text-rose-800 ml-2">{error}</div>}
      {!isSingle && (
        <div
          className="cursor-pointer text-sm flex text-blue-700 ml-2 items-center gap-1.5 font-medium"
          onClick={addInputToList}
          data-testid="inputlist_insert"
        >
          <PlusIcon />
          Add another
        </div>
      )}
    </div>
  );
};

export default memo(InputList);
