import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateFormMutation,
  usePublishFormMutation,
  useGetFormDraftQuery,
} from "store/services/updateFormBuilder";
import { publishFormState } from "Pages/Editor/utils";
import FormBuilder from "containers/FormBuilder";
import BlockNoteRenderer from "containers/BlockNoteRenderer";
import FormHeader from "components/FormHeader";
import Loader from "components/Loader";

export interface Data {
  name: string;
  type: string;
  structure: Array<Record<string, any>>;
}

export type FormStateType =
  | "builder"
  | "renderer"
  | "share"
  | "results"
  | "settings"
  | null;

const Form = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateType>("builder");

  const [updateForm, { isLoading }] = useUpdateFormMutation();
  const [publishForm, { isLoading: isPublishLoading }] =
    usePublishFormMutation();

  const {
    data,
    status,
    refetch: fetchFormDraft,
  } = useGetFormDraftQuery({
    id,
  });

  const { name, type, structure }: Data = (data || {
    name: "",
    type: "",
    structure: [{}],
  }) as Data;

  const formContent =
    Array.isArray(structure) && structure.length > 0 ? structure : [{}];

  return (
    <div className="min-h-screen flex flex-col">
      {formState !== "renderer" && (
        <>
          <FormHeader
            isUpdateLoading={isLoading}
            isPublishLoading={isPublishLoading}
            formState={formState}
            setFormState={(state: FormStateType) => setFormState(state)}
            publishFormState={() => publishFormState(id, publishForm, navigate)}
            formName={name}
            fetchFormDraft={fetchFormDraft}
          />
          <div className={"w-full h-[0.0625rem] border border-[#E5E7EB]"}></div>
        </>
      )}
      {status === "pending" ? (
        <div className="h-full flex items-center justify-center grow">
          <Loader />
        </div>
      ) : formState === "renderer" ? (
        <BlockNoteRenderer
          setFormState={setFormState}
          formContent={formContent}
          formType={type}
        />
      ) : (
        <FormBuilder
          updateForm={updateForm}
          id={id}
          formContent={formContent}
        />
      )}
    </div>
  );
};

export default Form;
