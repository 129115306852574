import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useSearchParams, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { SingleBlockType } from "components/Block/blockMenuList";
import { getElement } from "utils/getElement";
import { FORM_TYPE } from "utils/constant";
import { useGetFormStructureQuery } from "store/services/updateFormBuilder";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";
import _ from "lodash";

type initObjProps = {
  accessToken: string;
  assc: string | null;
  formId: string | null;
  isDevelopment: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  uniqueFiles: object[];
  identity: string;
  previewMode: boolean;
};

export const initobj: initObjProps = {
  accessToken: "",
  assc: "",
  formId: "",
  isDevelopment: false,
  isAndroid: false,
  isIOS: false,
  uniqueFiles: [],
  identity: "",
  previewMode: false,
};

export function submitForm() {
  window.parent.postMessage(
    {
      type: "submitEvaluationForm",
    },
    "*",
  );
}
export const initForm = (searchParams: any, initobj: initObjProps) => {
  if (searchParams) {
    if (searchParams.get("accessToken")) {
      initobj["accessToken"] = "Bearer " + searchParams.get("accessToken");
    }
    if (searchParams.get("mobile")) {
      initobj["isAndroid"] = true;
    }
    if (searchParams.get("dev")) {
      initobj["isDevelopment"] = true;
    }
    if (searchParams.get("ios")) {
      initobj["isIOS"] = true;
    }
    if (searchParams.get("assc")) {
      initobj["assc"] = searchParams.get("assc");
    }

    if (searchParams.get("formId")) {
      initobj["formId"] = searchParams.get("formId");
    }

    // setforminitObj(initobj);
    //pass query parameter previewMode=true to open form in preview mode.
    // if (
    //   searchParams.get("previewMode") &&
    //   searchParams.get("previewMode") === "true"
    // ) {
    //need to add the logic of preview mode true
    // 'evaluationForm'.find('select, input, textarea').attr('disabled', true);
    // 'saveFormDraft'.addClass('disabled')
    // }
  }

  return initobj;
};

export const getUniqueName = (name: string, uniqueNames: any[]) => {
  let count = 0;
  let uniqueName = name;
  while (uniqueNames.includes(uniqueName)) {
    count++;
    uniqueName =
      count === 1
        ? `${uniqueName} 1`
        : `${uniqueName.split(" ").slice(0, -1).join(" ")} ${count}`;
  }
  return uniqueName;
};

export const serializeData = (formData: any) => {
  let names: any = [];
  return Object.entries(formData).map((formobj: any) => {
    let value = formobj[1]?.value ? formobj[1].value : formobj[1];
    // const trimmed = formobj[0].split("_").slice(0, -2).join("_");
    const uniqueName = getUniqueName(formobj[0], names);
    names.push(uniqueName);
    return {
      label: uniqueName,
      name: uniqueName,
      value: value,
      key: value,
    };
  });
};

export const checkFormValidity = (
  formData: any,
  formRef: any,
  isPostback: boolean,
) => {
  if (!initobj.isDevelopment) {
    let form = formRef.current;
    if (form?.checkValidity && !form.checkValidity()) {
      const submitbutton = form.querySelector(
        'input[type="submit"]',
      ) as HTMLElement;
      submitbutton.click();
      return false;
    }
  }
  if (isPostback) {
    return postFormData(formData, formRef);
  }
  return true;
};

export const postMessage = (payload: any, type: string) => {
  if (initobj.isIOS) {
    (window as any).webkit.messageHandlers.iosClient.postMessage(
      JSON.stringify(payload),
    );
  } else if (initobj.isAndroid) {
    if (type === "formData") {
      // @ts-ignore
      AndroidForms.formData(JSON.stringify(payload));
    } else if (type === "formFile") {
      const formFile = payload?.formFile;
      const { file, fileName, type } = formFile || {};
      // @ts-ignore
      AndroidForms.formFile(file, fileName, type);
    }
  } else {
    window.parent.postMessage(payload, "*");
  }
};

export const postFormFile = (file: any, attrkey: any) => {
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      const filePayload = {
        type: "formFile",
        formFile: {
          type: attrkey,
          file: reader?.result,
          fileName: file?.name,
        },
      };
      postMessage(filePayload, "formFile");
    },
    false,
  );
  if (file) {
    reader.readAsDataURL(file);
  }
};

export const postFormData = (formData: any, formRef: any) => {
  if (checkFormValidity(formData, formRef, false)) {
    const formPayload = {
      type: "formData",
      formData: serializeData(formData),
    };
    console.log("FormData , initobj", formPayload.formData, initobj);
    postMessage(formPayload, "formData");
  } else {
    checkFormValidity(formData, formRef, true);
  }
};

export const handleSubmit = (event: {
  preventDefault: () => void;
  target: any;
}) => {
  event.preventDefault();
};

const FormRenderer = ({ layoutState, setFormState, formType }: any) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { data, isSuccess }: any = useGetFormStructureQuery(
    {
      id,
      state: "renderer",
    },
    { skip: !!layoutState },
  );
  const [deviceType, setDeviceType] = useState<"DESKTOP" | "MOBILE">("DESKTOP");
  const layouts = data?.layouts || layoutState;
  const formTypeValue = data?.type || formType;
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    initForm(searchParams, initobj);
    const eventMethod = "addEventListener";
    const eventer = window[eventMethod];
    const messageEvent = "message";

    eventer(messageEvent, (event) => {
      //Trigger formSubmit from parent (For Recruit)
      if (event.data.type === "submitEvaluationForm") {
        document.getElementById("submitButton")?.click();
        // postPersistFormData(null, true);
      }
    });
  }, [searchParams]);

  useEffect(() => {
    if (layouts) {
      const defaultForm: { [key: string]: any } = {};
      layouts.forEach((layout: any) => {
        layout?.blocks?.forEach((item: any) => {
          if (
            item &&
            item.properties &&
            item.properties.value &&
            item.properties.attrkey
          ) {
            defaultForm[item.properties.attrkey] = item.properties.value;
          }
        });
      });
      setFormData(defaultForm);
    }
  }, [isSuccess, layoutState]);

  // const postPersistFormData = (event: any, shouldPostFormData: boolean) => {
  //   if (shouldPostFormData && !checkFormValidity(formData, formRef, false)) {
  //     return false;
  //   }
  //   if (shouldPostFormData) {
  //     postFormData(formData, formRef);
  //   }
  // };

  const setData = (name: any, e: any) => {
    if (name) {
      setFormData((data) => {
        return {
          ...data,
          [name]: e,
        };
      });
    }
  };

  const getValue = (e: any, type: string) => {
    let objValue;
    Object.entries(formData).find((data: any) => {
      if (data[0] === e.attrkey) {
        objValue = data[1];
        return true;
      }
      return false;
    });
    if (type === "text") {
      return e.value;
    }
    return objValue;
  };

  const buttonProps = _.get(layouts, "[0].blocks[0].properties");
  return (
    <div
      className={classNames("max-h-screen w-full", "min-h-full", "bg-white")}
      data-testid="form_renderer"
    >
      {layoutState && (
        <div className="cursor-pointer w-full flex justify-end p-5 bg-white">
          <div className="mr-3">
            <Toggle
              labelClassNames="text-gray-500"
              label={"Mobile View"}
              onChange={(checked: boolean) => {
                if (checked) setDeviceType("MOBILE");
                else setDeviceType("DESKTOP");
              }}
              size="sm"
              checked={deviceType === "MOBILE"}
            />
          </div>
          <div
            role="button"
            data-testid="back-to-editor"
            onClick={() => setFormState("builder")}
            className="text-sm font-light	text-gray-500	"
            title="Back to Editor"
          >
            <FontAwesomeIcon icon={solid("right-from-line")} />
            Back to Editor
          </div>
        </div>
      )}
      <div
        className={classNames(
          "mx-auto mt-5 px-5 py-10 bg-white overflow-auto",
          layoutState
            ? " h-[85vh] max-h-[85vh] " +
                (deviceType === "MOBILE" ? "max-w-sm shadow-lg" : "max-w-6xl")
            : "max-h-screen h-screen",
        )}
      >
        {(isSuccess || !!layoutState) && (
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            id={
              formTypeValue === FORM_TYPE.REGISTRATION
                ? "assessmentForm"
                : "evaluationForm"
            }
          >
            {layouts?.map((layout: any, layoutIndex: number) => {
              return layout?.blocks?.map(
                (block: SingleBlockType, index: number) =>
                  !(layoutIndex === 0 && index === 0) && (
                    <div
                      className={classNames(
                        deviceType === "DESKTOP"
                          ? layout?.type?.value
                          : "w-full",
                        "p-1",
                      )}
                      key={block?.type + "renderer" + index}
                    >
                      {block?.type &&
                        block?.properties &&
                        React.cloneElement(getElement(block.type), {
                          ...block?.properties,
                          name: block?.properties?.attrkey,
                          value: getValue(block?.properties, block?.type),
                          state: "renderer",
                          onChange: (e: any) =>
                            setData(block?.properties?.attrkey, e),
                        })}
                    </div>
                  ),
              );
            })}
            <input type="submit" className="hidden" />
          </form>
        )}
        <div className="w-100 text-left pl-2.5">
          <button
            id="submitButton"
            className={`border rounded py-2 px-3 mt-10 bg-blue-700 ${
              formTypeValue !== FORM_TYPE.REGISTRATION && "hidden"
            } text-white text-sm font-medium text-center`}
            onClick={() => postFormData(formData, formRef)}
          >
            {buttonProps?.label}
          </button>
        </div>
      </div>
    </div>
  );
};

FormRenderer.propTypes = {
  layoutState: PropTypes.array,
  setFormState: PropTypes.func,
  formType: PropTypes.string,
};

export default FormRenderer;
