import React, { forwardRef, useImperativeHandle, useRef } from "react";
import _, { debounce, pickBy } from "lodash";
import {
  filterSuggestionItems,
  defaultBlockSpecs,
  BlockNoteSchema,
} from "@blocknote/core";
import {
  useCreateBlockNote,
  SuggestionMenuController,
  getDefaultReactSlashMenuItems,
  SideMenuController,
  SideMenu,
  DragHandleMenu,
  RemoveBlockItem,
  AddBlockButton,
  DragHandleButton,
} from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheckSquare,
  faFileAlt,
  faAt,
  faChevronDown,
  faDotCircle,
  faUpload,
  faCalendarAlt,
  faHashtag,
  faCodeFork,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { insertBlock } from "components/Tools/CustomBlocks/utils";
import {
  InputTextBlock,
  NumberBlock,
  EmailBlock,
  CheckBoxBlock,
  TextAreaBlock,
  DropDownBlock,
  RadioBlock,
  DatePickerBlock,
  FileUploadBlock,
  LogicBlock,
  customBlocksTypes,
  logicSupported,
} from "components/Tools/CustomBlocks";
import MenuBox from "components/Popover/MenuPopover/MenuBox";
import {
  ConditionLogicBtn,
  HideAndShowBlock,
} from "components/Tools/CustomBlocks/SideMenuOptions";
import { propertyBox, validationBox } from "components/Block/blockMenuList";

export interface EditorInstance {
  getBlockEditor: () => BlockEditor;
}

export type BlockEditor = {
  document: Array<{
    props: { attrkey?: string; value?: any };
  }>;
};

interface BlockNoteEditorProps {
  onChange?: () => void;
  formContent: any[];
  isEditable: boolean;
}

export const schema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    input: InputTextBlock,
    number: NumberBlock,
    email: EmailBlock,
    checkbox: CheckBoxBlock,
    textarea: TextAreaBlock,
    select: DropDownBlock,
    radio: RadioBlock,
    datepicker: DatePickerBlock,
    file: FileUploadBlock,
    logic: LogicBlock,
  },
});

const BlockNoteEditor = forwardRef<unknown, BlockNoteEditorProps>(
  ({ formContent, isEditable, onChange }, ref) => {
    const menuFieldsRef = useRef<any>(null);
    const {
      input,
      number,
      email,
      checkbox,
      textarea,
      select,
      radio,
      datepicker,
      file,
      logic,
    } = customBlocksTypes;

    const creatBlockNoteObj = {
      schema,
      initialContent: formContent,
    };

    const editor = useCreateBlockNote(creatBlockNoteObj);
    const inputTextBox = insertBlock(editor, input, "Short text", faEdit);
    const numberBox = insertBlock(editor, number, "Number", faHashtag);
    const emailBox = insertBlock(editor, email, "Email", faAt);
    const checkboxBlock = insertBlock(
      editor,
      checkbox,
      "Checkboxes",
      faCheckSquare,
    );
    const textAreaBox = insertBlock(editor, textarea, "Long text", faFileAlt);
    const dropDownBox = insertBlock(editor, select, "Dropdown", faChevronDown);
    const radioBox = insertBlock(editor, radio, "Multiple choice", faDotCircle);
    const fileBox = insertBlock(editor, file, "File upload", faUpload);
    const datePickerBox = insertBlock(
      editor,
      datepicker,
      "Date",
      faCalendarAlt,
    );
    const logicBox = insertBlock(
      editor,
      logic,
      "Conditional Logic",
      faCodeFork,
      "Advanced Blocks",
    );

    const getCustomBlockMenuItems = () => {
      return [
        inputTextBox,
        textAreaBox,
        radioBox,
        checkboxBlock,
        dropDownBox,
        fileBox,
        numberBox,
        emailBox,
        datePickerBox,
      ];
    };

    const getCustomSlashMenuItems = (editor: typeof schema.BlockNoteEditor) => {
      try{
        const customItems = getCustomBlockMenuItems();
        let defaultItems = getDefaultReactSlashMenuItems(editor)
          .filter((i) => i?.group !== "Media" && i?.group !== "Others")
          .map((i) => {
            let updatedItem = { ...i };
            if (i.group === "Headings") {
              updatedItem.group = "Text Blocks";
            }
            if (i.title === "Paragraph") {
              updatedItem.title = "Text";
              updatedItem.group = "Text Blocks";
            }
            if (i.title === "Table") {
              updatedItem.group = "Text Blocks";
            }

            if (i.group === "Basic blocks"){
              updatedItem.group = "Text Blocks";
            }
            return updatedItem;
          });
        const heading3Index = defaultItems.findIndex(
          (item) => item.title === "Heading 3",
        );
        const textItemIndex = defaultItems.findIndex(
          (item) => item.title === "Text",
        );
        const tableItemIndex = defaultItems.findIndex(
          (item) => item.title === "Table",
        );

        if (heading3Index !== -1) {
          if (textItemIndex !== -1) {
            const [textItem] = defaultItems.splice(textItemIndex, 1);
            const targetIndexForText = heading3Index + 1;
            defaultItems.splice(targetIndexForText, 0, textItem);

            if (tableItemIndex !== -1) {
              const tableItemIndexAdjusted = defaultItems.findIndex(
                (item) => item.title === "Table",
              );
              const [tableItem] = defaultItems.splice(tableItemIndexAdjusted, 1);
              const targetIndexForTable = targetIndexForText + 1;
              defaultItems.splice(targetIndexForTable, 0, tableItem);
            }
          }
        }

        return [...customItems, ...defaultItems, logicBox];
      }
      catch(e){
        console.error(e);
        return [...getCustomBlockMenuItems(), ...getDefaultReactSlashMenuItems(editor), logicBox];
      }
    };

    const onBlockNoteChange = () => {
      if (onChange) onChange();
    };

    const debouncedBlockNoteChange = debounce(onBlockNoteChange, 1000);

    useImperativeHandle(ref, () => ({
      getBlockEditor: () => editor,
    }));

    const onMenuclose = (
      block: string | { id: string },
      unfreezeMenu: () => void,
    ) => {
      const blockProps = menuFieldsRef.current;
      if (blockProps) {
        const properties = _(blockProps)
          .mapValues((item) => ({
            key: item.key,
            value:
              item.hasToggle && item.isEnabled === false ? null : item.value, // Set value to null if disabled
          }))
          .mapKeys((item) => item.key) // Use the 'key' as the new key
          .mapValues((item) => item.value) // Assign 'value' as the new value
          .value();

        const updatedToggleState = Object.fromEntries(
          blockProps
            .filter((item: any) => item?.hasToggle)
            .map((item: any) => [item?.key, item?.isEnabled ?? false]),
        );

        try {
          editor?.updateBlock(block, {
            props: { ...properties, toggleState: updatedToggleState },
          });
        } catch (error) {
          console.error("Error updating block properties", error);
        }
      }
      unfreezeMenu();
      menuFieldsRef.current = null;
    };

    return (
      <BlockNoteView
        editor={editor}
        slashMenu={false}
        onChange={debouncedBlockNoteChange}
        editable={isEditable}
        theme="light"
        sideMenu={false}
      >
        <SideMenuController
          sideMenu={(props) => {
            const { block, unfreezeMenu, addBlock } = props || {};
            const { props: blockProps, type } = block || {};
            const isCustomBlock =
              Object.keys(customBlocksTypes)?.includes(type) &&
              type !== customBlocksTypes.logic;
            const filteredProps = pickBy(blockProps, (value) => value !== null);

            return (
              <SideMenu {...props}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-gray-400 cursor-pointer hover:bg-gray-200 rounded-md p-1.5"
                  onClick={() => editor.removeBlocks([block])}
                />
                <AddBlockButton addBlock={addBlock} />
                <DragHandleButton
                  {...props}
                  unfreezeMenu={() => onMenuclose(block, unfreezeMenu)}
                  dragHandleMenu={(props) => {
                    return (
                      <DragHandleMenu {...props}>
                        {isCustomBlock && (
                          <>
                            <MenuBox
                              propertyBox={propertyBox[type]}
                              validations={validationBox[type]}
                              block={{ type, properties: filteredProps }}
                              menuFieldsRef={menuFieldsRef}
                            />
                            <div className="border-[0.5px] border-gray-200"></div>
                          </>
                        )}
                        <RemoveBlockItem {...props}>
                          <div>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-gray-600 mr-2"
                            />
                            Delete
                          </div>
                        </RemoveBlockItem>
                        <HideAndShowBlock
                          block={block}
                          blockProps={blockProps}
                        />
                        {logicSupported.includes(type) && (
                          <ConditionLogicBtn
                            block={block}
                            menuFieldsRef={menuFieldsRef}
                          />
                        )}
                      </DragHandleMenu>
                    );
                  }}
                />
              </SideMenu>
            );
          }}
        />
        <SuggestionMenuController
          triggerCharacter="/"
          getItems={async (query) =>
            filterSuggestionItems(getCustomSlashMenuItems(editor), query)
          }
        />
      </BlockNoteView>
    );
  },
);

export default BlockNoteEditor;
