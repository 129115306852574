import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "components/Loader";
import Input from "components/Tools/Input";
import RadioButtonGroup from "components/Tools/RadioButtonGroup";
import { useCreateFormMutation } from "store/services/updateFormBuilder";

const Home = () => {
  const [isEditFormTitle, setisEditFormTitle] = useState(true);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState("");

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const [createForm, { isLoading }] = useCreateFormMutation();

  const createFormBuilder = async () => {
    setError("");
    if (formData.title && formData.type) {
      try {
        const form: any = await createForm({
          name: formData.title,
          type: formData.type?.value,
        });
        if (form?.error) {
          setError(form?.error.message);
        } else {
          navigate(
            `/builder/${
              form?.data?.frm_create_form?.data?.id
            }?accessToken=${searchParams.get("accessToken")}`,
            { replace: true }
          );
        }
      } catch (err) {}
    } else {
      setError("Form Name and Type is required to create the Form.");
    }
  };

  return (
    <div className="m-auto w-80 mt-2">
      <div
        onClick={() => setisEditFormTitle(true)}
        className="text-center cursor-pointer"
      >
        Create Form
      </div>
      {isEditFormTitle && (
        <div className="mt-2 shadow-lg p-2 rounded ">
          <Input
            onChange={(e) =>
              setFormData((data) => ({ ...data, title: e && e.trim() }))
            }
            placeholder="Enter Form name"
          />
          <br />
          <RadioButtonGroup
            value={formData?.type}
            label="Please select the Form type"
            onChange={(e: any) => setFormData((data) => ({ ...data, type: e }))}
            options={[
              { value: "Registration", label: "Candidate registration form" },
              { value: "Evaluation", label: "Panel Evaluation form" },
            ]}
          />
          {error && <span className="text-red-600 text-xs mt-4">{error}</span>}
          <br />
          <div className="flex justify-center">
            {isLoading ? (
              <Loader />
            ) : (
              <div
                onClick={createFormBuilder}
                className="text-center cursor-pointer my-2 hover:underline "
              >
                Go to Form
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
