import { FormStateType } from "Pages/Editor";
import PreviewButton from "components/FormHeader/PreviewButton";
import PublishButton from "components/FormHeader/PublishButton";
import ThreeDotMenu from "components/FormHeader/ThreeDotMenu";
import HeaderTabs from "components/HeaderTabs";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import SavedIndicator from "components/FormHeader/SavedIndicator";
import React from "react";

type FormHeaderType = {
  formState: FormStateType;
  setFormState: (state: FormStateType) => void;
  publishFormState: () => void;
  isUpdateLoading: boolean;
  isPublishLoading: boolean;
  formName: string;
  fetchFormDraft: () => void;
};

const tabs = [
  { id: 4, pane: "builder", name: "Create" },
  { id: 0, pane: "home", name: "Home" },
  { id: 1, pane: "share", name: "Share" },
  { id: 2, pane: "results", name: "Results" },
  { id: 3, pane: "settings", name: "Settings" },
];

const FormName = ({ name }: { name: string }) => {
  return (
    <div className="text-[21px] mt-[1.06rem] text-gray-900 font-semibold w-[17.5rem] h-[2.5rem]  items-center">
      {name}
    </div>
  );
};
const FormHeader = ({
  formState,
  setFormState,
  isUpdateLoading,
  publishFormState,
  isPublishLoading,
  formName,
  fetchFormDraft,
}: FormHeaderType) => {
  return (
    <div>
      <div className="pl-[2rem] pr-[1.5rem] space-x-[1.16rem] h-16 w-full flex justify-between items-center">
        <div className="flex">
          <ArrowLeftIcon
            data-testid="back_button"
            className="w-[1.5rem] h-[1.5rem] mr-[1rem] mb-[1.13rem] mt-[1.37rem]"
          />
          <FormName name={formName} />
        </div>
        <div className="w-[82.82125rem] h-[4rem] ml-[30.63rem]">
          <HeaderTabs tabs={tabs} defaultTab={4} />
        </div>
        <div className="flex items-center justify-center">
          <SavedIndicator isUpdateLoading={isUpdateLoading} />
          <PreviewButton
            isUpdateLoading={isUpdateLoading}
            onclick={() => {
              fetchFormDraft();
              setFormState("renderer");
            }}
          />
          <PublishButton
            formState={formState}
            publishFormState={publishFormState}
            isPublishLoading={isPublishLoading}
          />
          <ThreeDotMenu />
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
