import { useEffect, useRef, useState } from "react";
import { isArrayEqual } from "utils";
import CheckBox from "./CheckBox";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import "react-tooltip/dist/react-tooltip.css";

const CheckboxTree = (props: any) => {
  const { options, label, value, onChange, required, tooltip } = props;
  const [boxList, setBoxList] = useState(options || []);
  const inputRef = useRef<any>(null);
  const updateValue = (checked: boolean, _option: any, i: number) => {
    const option = { ..._option };
    const list = [...boxList];
    option.checked = checked;
    list.splice(i, 1, option);
    setBoxList(list);
  };

  const updateDefaultValues = (_value: any, _options: any) => {
    if (_value && Array.isArray(_value)) {
      const values = _value?.map((value: any) => value.value);
      const list = _options.map((option: any) => ({
        ...option,
        checked: values.includes(option.value),
      }));
      setBoxList(list);
    }
  };

  useEffect(() => {
    const filteredValue = boxList
      .filter((option: any) => option.checked)
      .map((option: any) => ({ label: option.label, value: option.value }));
    if (!isArrayEqual(value, filteredValue)) onChange(filteredValue);
  }, [boxList]);

  useEffect(() => {
    value && updateDefaultValues(value, boxList);
  }, [value]);

  useEffect(() => {
    const list = boxList.map((option: any) => ({
      id: option?.id,
      name: option?.name,
      label: option?.label,
      value: option?.option,
    }));
    if (!isArrayEqual(list, options)) {
      const filteredValue = value?.map((option: any) => option.value);
      const newOptions = options.map((option: any) => {
        if (filteredValue && filteredValue?.includes(option.value)) {
          return { ...option, checked: true };
        } else return { ...option, checked: false };
      });
      setBoxList(newOptions);
    }
  }, [options]);

  useEffect(() => {
    if (
      required &&
      !(boxList?.filter((option: any) => option.checked).length > 0)
    )
      inputRef.current?.setCustomValidity(`This is a required field`);
    else inputRef.current?.setCustomValidity("");
  }, [boxList]);
  return (
    <div data-testid="checkbox" className="bg-white">
      <input
        ref={inputRef}
        defaultValue={value || ""}
        className="absolute opacity-0 pointer-events-none"
        role="button"
      />
      {(label || required) && (
        <div className="mb-1">
          <label className="text-sm mb-1">
            {label}
            {required && "*"}
          </label>
          {tooltip && (
            <InformationCircleIcon
              className={`ml-2 w-3 h-3 flex-col justify-center items-center inline-flex text-neutral-400`}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={tooltip}
            />
          )}
          <ReactTooltip id="my-tooltip" place="top" />
        </div>
      )}
      <div className="flex flex-col gap-2 w-auto">
        {boxList?.map((option: any, i: number) => (
          <CheckBox
            id={option.value}
            key={option.label + i}
            {...props}
            label={option.label}
            value={option.value || ""}
            checked={!!option?.checked}
            onChange={(e) => updateValue(e, option, i)}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxTree;
