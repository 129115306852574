import React from "react";
import { useBlockNoteEditor, useComponentsContext } from "@blocknote/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeFork,
  faEye,
  faEyeSlash,
} from "@fortawesome/pro-regular-svg-icons";

export function HideAndShowBlock({ block, blockProps }: any) {
  const editor = useBlockNoteEditor();
  const Components = useComponentsContext()!;
  const isHidden = blockProps?.hide;
  return (
    <Components.Generic.Menu.Item
      onClick={() => {
        // @ts-ignore
        editor.updateBlock(block, { props: { hide: !isHidden } });
      }}
    >
      <div>
        <FontAwesomeIcon
          icon={isHidden ? faEye : faEyeSlash}
          className="text-gray-600 mr-2"
        />
        <span>{isHidden ? "Show" : "Hide"}</span>
      </div>
    </Components.Generic.Menu.Item>
  );
}

export function ConditionLogicBtn({ block, menuFieldsRef }: any) {
  const editor = useBlockNoteEditor();
  const Components = useComponentsContext()!;

  const getActionBlock = (data: any) => {
    let value = null;
    for (const item of data) {
      if (item?.key === "attrkey" && item?.value) {
        return { label: item.value, value: item.value };
      }
      if (item?.key === "label" && item?.value) {
        value = { label: item.value, value: item.value };
      }
    }
    return value;
  };

  return (
    <Components.Generic.Menu.Item
      onClick={() => {
        const actionBlock = getActionBlock(menuFieldsRef.current);
        editor.insertBlocks(
          [
            {
              // @ts-ignore
              type: "logic",
              props: {
                // @ts-ignore
                actionBlock: actionBlock || null,
              },
            },
          ],
          block,
          "after",
        );
      }}
    >
      <div>
        <FontAwesomeIcon icon={faCodeFork} className="text-gray-600 mr-2" />
        <span>Add conditional logic</span>
      </div>
    </Components.Generic.Menu.Item>
  );
}
