import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import Home from "./Pages/Home";
import BlockNoteRenderer from "containers/BlockNoteRenderer";
import Editor from "./Pages/Editor";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { updateFormBuilderApi } from "./store/services/updateFormBuilder";

import { growthbook } from "./utils/growthbook";
function App() {
  useEffect(() => {
    growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <ApiProvider api={updateFormBuilderApi}>
        <BrowserRouter>
          <Routes>
            <Route path="/builder/:id" element={<Editor />} />
            <Route path="/renderer/:id" element={<BlockNoteRenderer />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </ApiProvider>
    </GrowthBookProvider>
  );
}

export default App;
