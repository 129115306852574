import React, { memo } from 'react';
import classNames from 'classnames';
import { FormStateType } from "Pages/Editor";

type TabType = {
  tab : any,
  selectedTab : Number,
  handleTabChange : (state: FormStateType) => void;
}

const Tab = (props : TabType) => {
  const { tab = {}, selectedTab = 4, handleTabChange } = props;
  const isActive = selectedTab === tab.id;

  const onClick = () => {

    if (handleTabChange) {
      handleTabChange(tab.id);
    }
  };
  if (tab && tab.id && tab.name) {
    return (
      <div className={""}>
        <div
          onClick={onClick}
          className={classNames(
            isActive
              ? ' text-[#1D4ED8] text-[14px] font-medium leading-tight' :
              // eslint-disable-next-line max-len
              'text-gray-500 text-[14px]  font-medium leading-tight',
            'whitespace-nowrap flex pt-[1.675rem] px-[0.25rem] pb-[1.25rem] leading-4 text-sm cursor-pointer mx-1',
          )}
          role="tab"
          aria-current={tab.id === selectedTab ? 'true' : 'false'}
        >
          {tab.name}
          {tab.count ? <span className="px-1">({tab.count})</span> : null}
        </div>
        {isActive?<div className="self-stretch h-[0.125rem] bg-blue-700" />:<div className="self-stretch h-[0.125rem]" />}
      </div>
    );
  }
  return null;
};

export default memo(Tab);
